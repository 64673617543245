import {
  createContext,
  ReactNode,
  useContext,
  useState,
  Context,
  SetStateAction,
} from 'react';
import {
  SignedInUser,
  ProjectObject,
  SmartSuiteFileListObject,
} from '@/typescript/interfaces';
import { getProject } from '@/utils/authenticated-requests/projects';
import { errorHandler } from '@/utils/error/error-handler';

export interface AppState {
  user: SignedInUser;
  company: string; // for switch companies
  setCompany: (value: string | '') => void;
  appLoading: boolean;
  setAppLoading: (value: boolean) => void;
  activeProject: ProjectObject;
  setActiveProject: (value: ProjectObject | object) => void;
  refreshProjectObject: () => Promise<void>;
  jobListOpen: boolean;
  setJobListOpen: (value: boolean) => void;
  isDrawerOpen: boolean;
  setIsDrawerOpen: (value: boolean) => void;
}

export const AppContext = createContext<AppState>({
  user: {} as SignedInUser,
  company: '', // for switch companies
  setCompany: (value: string) => '',
  appLoading: false,
  setAppLoading: (value: boolean): void => {
    /** TODO */
  },
  // The project is selected via URL
  activeProject: {},
  setActiveProject: (value: ProjectObject): void => {
    /** TODO */
  },
  refreshProjectObject: (): Promise<void> => {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  jobListOpen: false,
  setJobListOpen: (value: boolean): void => {
    /** TODO */
  },
  isDrawerOpen: true,
  setIsDrawerOpen: (value: boolean): void => {
    /** TODO */
  },
});

export const AppContextProvider = ({
  children,
  user,
}: AppContextProviderProps) => {
  // Store global state here.
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);
  const [appLoading, setAppLoading] = useState<boolean>(false);
  const [activeProject, setActiveProject] = useState<ProjectObject>({});
  const [jobListOpen, setJobListOpen] = useState<boolean>(false);
  const [company, setCompany] = useState<string>(
    user.signInUserSession?.idToken.payload['custom:company'] || ''
  ); //default company

  /**
   * Get project object and update context
   */
  const refreshProjectObject = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (activeProject?.id) {
        getProject(company, activeProject.id, true)
          .then((value: ProjectObject) => {
            setActiveProject(value);
            resolve();
          })
          .catch((err) => {
            errorHandler(err);
            reject(err);
          });
      } else {
        resolve();
      }
    });
  };

  return (
    <AppContext.Provider
      value={{
        user,
        company,
        setCompany,
        appLoading,
        setAppLoading,
        activeProject,
        setActiveProject,
        refreshProjectObject,
        jobListOpen,
        setJobListOpen,
        isDrawerOpen,
        setIsDrawerOpen,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

interface AppContextProviderProps {
  children: ReactNode;
  user: SignedInUser;
}

/**
 * Custom hook for accessing global app state.
 * @returns AppState
 */
export const useStore = () => useContext(AppContext);
