import { useContext } from 'react';
import { AppAlertsContext } from '@/root/App';
import { GridColDef } from '@mui/x-data-grid-pro';
import { TableRow, DomainMetadata } from '@/typescript/types';

export const useAppErrorAlert = () => {
  const { addAppAlert } = useContext(AppAlertsContext);

  /**
   * Handle error alerts in the application.
   * It extracts the error message from the error object, if available, otherwise it uses the default message.
   * The function then calls the `addAppAlert` function with an object containing the severity of the alert (always 'error' in this case),
   * the error message, and optionally the error details if available in the error object.
   * @param {any} error - error object from API response.
   * @param {string} defaultMessage - The default error message to use if no message is available in the error object.
   */
  const handleAppErrorAlerts = (error: any, defaultMessage: string) => {
    const errorResponseData: { error?: string; message?: string } =
      error?.error?.response?.data;
    const details = errorResponseData?.error ?? error?.error?.message;

    addAppAlert({
      severity: 'error',
      message: errorResponseData?.message ?? defaultMessage,
      ...(details && { details }),
    });
  };

  return handleAppErrorAlerts;
};

export const validateTextInput = (input: string): boolean => {
  const regex = /^[a-zA-Z0-9_:-]*$/;
  return regex.test(input);
};

export const validateFloatInput = (input: string): boolean => {
  const floatRegex = /^(?!0\d)\d+(\.\d{0,2}(0)?(?!0{1,}))?$/;
  return floatRegex.test(input);
};

export const validateIntegerInput = (input: string): boolean => {
  const integerRegex = /^[0-9]*$/;
  return integerRegex.test(input);
};

export const validateNumberRangeInput = (
  input: number,
  min: number,
  max: number
) => {
  return input >= min && input <= max;
};

// Validate file name input
export const validateFileNameInput = (input: string): boolean => {
  if (input === '') {
    return false;
  }
  const regex = /^[a-zA-Z0-9_:-]*$/;
  return regex.test(input);
};

export const formatTime = (timestamp: string): string => {
  const [hours, minutes, seconds] = timestamp.split(':').map(Number);
  const date = new Date();
  date.setHours(hours, minutes, seconds);

  const parts = [];
  if (hours > 0) {
    parts.push(`${hours} hours`);
  }
  if (minutes > 0) {
    parts.push(`${minutes} min`);
  }
  if (seconds > 0) {
    parts.push(`${seconds} sec`);
  }
  return parts.join(' ').trim();
};

/**
 * Builds a query string from the given parameters object.
 *
 * @param {Record<string, string | number | null>} params - The parameters object containing key-value pairs.
 * @returns {string} The query string constructed from the parameters object.
 */
export function parseQueryParams(params?: Record<string, any>): string {
  if (!params) return '';

  const queryParams = [];

  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const value = params[key];
      if (value) {
        queryParams.push(`${key}=${encodeURIComponent(value)}`);
      }
    }
  }

  const queryString = queryParams.length > 0 ? queryParams.join('&') : '';

  if (queryString.length > 0) {
    return '?' + queryString;
  } else {
    return queryString;
  }
}

export const sortRasterFileNames = (files: string[]): string[] => {
  return files.sort((a, b) => {
    // Extract the numeric part from the strings.
    const numA = parseInt(a.replace(/\D/g, ''), 10);
    const numB = parseInt(b.replace(/\D/g, ''), 10);
    // Extract the non-numeric part from the strings.
    const strA = a.replace(/\d/g, '');
    const strB = b.replace(/\d/g, '');

    // If the non-numeric parts are equal, sort by the numeric part.
    // Otherwise sort alphabetically.
    if (strA === strB) {
      return numA - numB;
    } else {
      return strA.localeCompare(strB);
    }
  });
};

export const removeFileExtension = (filename: string): string => {
  return filename.split('.').slice(0, -1).join('.');
};

/**
 * Get the file name from a URL.
 * If the URL is not a valid URL, the function will extract the file name from the URL string.
 * @param {string} fileUrl - The URL of the file.
 * @param {boolean} withFileExtension [false by default] - Whether to include the file extension in the file name.
 * @returns {string} The file name extracted from the URL with or without the file extension.
 */
export const getFileName = (
  fileUrl: string,
  withFileExtension = false
): string => {
  let filename = '';

  try {
    const url = new URL(fileUrl);
    filename = url.pathname.split('/').pop() || '';
  } catch (err) {
    filename = fileUrl.split('/').pop() || '';
    filename = withFileExtension ? filename : removeFileExtension(filename);
  }

  return filename;
};

export const isEmptyObject = (obj: any): boolean => {
  return [null, undefined].includes(obj) || Object.keys(obj).length === 0;
};

/**
 * React MUI Data Grid helper functions
 */
/**
 * Create an array of column definitions for a DataGridPro component from an object's keys.
 * Each column definition includes the field name, header name, width, and header alignment.
 * @param {Record<string, any>} data - The object from which to create column definitions.
 * @param {Partial<GridColDef>} customProps - The custom properties to be applied to each column.
 * @returns {GridColDef[]} An array of column definitions for a DataGridPro component.
 */
export const createColumnsFromKeys = (
  data: Record<string, any>,
  customProps: Partial<GridColDef> = {}
): GridColDef[] => {
  return Object.keys(data).map((column) => ({
    field: column,
    headerName: column,
    ...customProps,
  }));
};

/**
 * Create an array of row objects from an object's keys.
 * Each object in the resulting array of rows corresponds to a row in the table, and has a property for each key in the input object.
 * The value of each property is taken from the corresponding array in the input object.
 * If the arrays in the input object are of different lengths, the output array will be as long as the longest array,
 * and properties corresponding to shorter arrays will be filled with an empty string.
 * @param {Record<string, any>} data - The object from which to create rows.
 * @returns {TableRow[]} An array of row objects for a DataGridPro component.
 */
export const createRowsFromKeys = (data: Record<string, any>): TableRow[] => {
  // Get the keys and find the length of the longest array
  const keys = Object.keys(data);
  const maxLength = Math.max(...keys.map((key) => data[key].length));
  // Create an array of row objects, with each object containing a property for each key
  const rows: TableRow[] = Array.from({ length: maxLength }, (_, i) =>
    keys.reduce((obj, key) => ({ ...obj, [key]: data[key][i] || '' }), {})
  );
  return rows;
};

/**
 * Parse -9999 values from the table rows into customizable 'No Data' text value and return the new table rows.
 * @param {TableRow[]} rows - The table rows to parse
 * @param {string} noDataText - The customizable text to replace the 'No Data' values
 * @returns The new table rows with customizable 'No Data' text values
 */
export const parseNoDataFromTableRows = (
  rows: TableRow[],
  noDataText: string
): TableRow[] => {
  return rows.map((row) => {
    return Object.keys(row).reduce((acc, key) => {
      acc[key] = row[key] === -9999 ? noDataText : row[key];
      return acc;
    }, {} as TableRow);
  });
};

/**
 * Transform the domain metadata to an array of table rows.
 * Each row represents a combination of domain and its corresponding rows.
 * @param {DomainMetadata} metadata - The domain metadata to transform.
 * @returns An array of table rows.
 */
export const transformObjectToDomainAndRows = (
  metadata: DomainMetadata
): TableRow[] => {
  const keys = Object.keys(metadata);

  return keys.reduce((result: TableRow[], key) => {
    const subKeys = Object.keys(metadata[key]);

    subKeys.forEach((subKey, index) => {
      if (result[index] === undefined) {
        result[index] = {};
      }

      result[index][key] = subKey;
      result[index][`${key}_rows`] = metadata[key][subKey];
    });

    return result;
  }, []);
};
