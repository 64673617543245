/** React imports */
import {
  createContext,
  useState,
  useMemo,
  Context,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
/** Goldspot imports */
import { useStore } from '@/hooks/useStore';
import { errorHandler } from '@/utils/error/error-handler';
import { getFileDirectory } from '@/utils/authenticated-requests/files';
import {
  CloudFile,
  SmartSuiteFileListObject,
  FileDirectoryResponse,
  TreeNode,
  NumericalFileData,
  CubeBuildingJobResponse,
  CubeBuildingJobData,
  CubeBuildingFileData,
  PCAJobResponse,
  PCAJobData,
  BaselineModellingJobResponse,
  TuningModellingJobResponse,
  ClusteringJobData,
  ClusteringJobResponse,
  ClusterSetting,
} from '@/typescript/interfaces';
import {
  SmartSuiteJobType,
  SmartSuiteDataType,
  SmartSuiteFileFormat,
  FileUploadStatus,
  SmartSuiteFileExtensions,
} from '@/typescript/enums';
import { DirectoryItem } from '@/typescript/types';
import {
  SMART_SUITE_APP_FOLDERS,
  REQUIRED_SHAPEFILES_EXT,
} from '@/utils/constants/smartSuiteAppConstants';

export interface SmartSuiteContextType {
  smartsuiteJobType: SmartSuiteJobType | undefined;
  setSmartsuiteJobType: Dispatch<SetStateAction<SmartSuiteJobType | undefined>>;
  rawRasterNumericalFiles: SmartSuiteFileListObject[];
  rawRasterCategoricalFiles: SmartSuiteFileListObject[];
  rawShapeFiles: SmartSuiteFileListObject[];
  rawTrainingTargetFiles: SmartSuiteFileListObject[];
  rawTargetVectorFiles: SmartSuiteFileListObject[];
  processedNumericalFiles: SmartSuiteFileListObject[];
  resetSmartSuiteFiles: () => void;
  groupSmartSuiteFiles: (file: SmartSuiteFileListObject) => void;
  loadSmartSuiteDirectoryItems: (projectId: string) => Promise<DirectoryItem[]>;
  deleteSmartSuiteFile: (node: TreeNode) => void;
  isSmartSuiteFile: (filename: string) => boolean;
  hasRasterNumericalFiles: boolean;
  hasRasterCategoricalFiles: boolean;
  hasShapefiles: boolean;
  hasTargetVectorFiles: boolean;
  hasTargetRasterFiles: boolean;
  numericalFileData: NumericalFileData[];
  setNumericalFileData: Dispatch<SetStateAction<NumericalFileData[]>>;
  cubeData: CubeBuildingJobResponse;
  setCubeData: Dispatch<SetStateAction<CubeBuildingJobResponse>>;
  inputCubeFileName: string;
  outputCubeFileName: string | undefined;
  findDomainsForPCA: (cubeJobData: CubeBuildingJobData) => void;
  domainsForPCA: string[];
  setDomainsForPCA: Dispatch<SetStateAction<string[]>>;
  pcaData: PCAJobResponse;
  setPcaData: Dispatch<SetStateAction<PCAJobResponse>>;
  pcaJobMetadata: PCAJobData;
  setPcaJobMetadata: Dispatch<SetStateAction<PCAJobData>>;
  cubeBuildJobMetadata: CubeBuildingJobData;
  setCubeBuildJobMetadata: Dispatch<SetStateAction<CubeBuildingJobData>>;
  modellingInputFeatures: Record<string, string[]>;
  setModellingInputFeatures: Dispatch<SetStateAction<Record<string, string[]>>>;
  modellingTargetFiles: string[];
  setModellingTargetFiles: Dispatch<SetStateAction<string[]>>;
  modellingData: BaselineModellingJobResponse | TuningModellingJobResponse;
  setModellingData: Dispatch<
    SetStateAction<BaselineModellingJobResponse | TuningModellingJobResponse>
  >;
  clusteringJobMetadata: ClusteringJobData;
  setClusteringJobMetadata: Dispatch<SetStateAction<ClusteringJobData>>;
  clusteringData: ClusteringJobResponse;
  setClusteringData: Dispatch<SetStateAction<ClusteringJobResponse>>;
  secondaryClusterSetting: ClusterSetting;
  setSecondaryClusterSetting: Dispatch<SetStateAction<ClusterSetting>>;
}

export const SmartSuiteContext: Context<SmartSuiteContextType> = createContext(
  {} as SmartSuiteContextType
);

export const SmartSuiteContextProvider = ({
  children,
}: SmartSuiteContextProviderProps) => {
  const { setAppLoading, company } = useStore();
  const [smartsuiteJobType, setSmartsuiteJobType] = useState<
    SmartSuiteJobType | undefined
  >(undefined);
  const [rawRasterNumericalFiles, setRawRasterNumericalFiles] = useState<
    SmartSuiteFileListObject[]
  >([]);
  const [rawRasterCategoricalFiles, setRawRasterCategoricalFiles] = useState<
    SmartSuiteFileListObject[]
  >([]);
  const [rawShapeFiles, setRawShapeFiles] = useState<
    SmartSuiteFileListObject[]
  >([]);
  const [rawTrainingTargetFiles, setRawTrainingTargetFiles] = useState<
    SmartSuiteFileListObject[]
  >([]);
  const [rawTargetVectorFiles, setRawTargetVectorFiles] = useState<
    SmartSuiteFileListObject[]
  >([]);
  const [processedNumericalFiles, setProcessedNumericalFiles] = useState<
    SmartSuiteFileListObject[]
  >([]);
  const [numericalFileData, setNumericalFileData] = useState<
    NumericalFileData[]
  >([]);
  const [cubeData, setCubeData] = useState<CubeBuildingJobResponse>(
    {} as CubeBuildingJobResponse
  );
  const [domainsForPCA, setDomainsForPCA] = useState<string[]>([]);
  const [pcaData, setPcaData] = useState<PCAJobResponse>({} as PCAJobResponse);
  const [pcaJobMetadata, setPcaJobMetadata] = useState<PCAJobData>(
    {} as PCAJobData
  );
  const [cubeBuildJobMetadata, setCubeBuildJobMetadata] =
    useState<CubeBuildingJobData>({} as CubeBuildingJobData);

  // SmartSuite Modelling
  const [modellingInputFeatures, setModellingInputFeatures] = useState<
    Record<string, string[]>
  >({});
  const [modellingTargetFiles, setModellingTargetFiles] = useState<string[]>(
    []
  );
  const [modellingData, setModellingData] = useState<
    BaselineModellingJobResponse | TuningModellingJobResponse
  >({} as BaselineModellingJobResponse | TuningModellingJobResponse);

  // SmartSuite Clustering
  const [clusteringJobMetadata, setClusteringJobMetadata] =
    useState<ClusteringJobData>({} as ClusteringJobData);
  const [clusteringData, setClusteringData] = useState<ClusteringJobResponse>(
    {} as ClusteringJobResponse
  );
  const [secondaryClusterSetting, setSecondaryClusterSetting] =
    useState<ClusterSetting>({} as ClusterSetting);

  const hasSmartSuiteFiles = (smartSuiteFile: SmartSuiteFileListObject[]) =>
    smartSuiteFile.length > 0;

  /**
   * Check if all the required extensions are present for each shapefile,
   * returning true if at least of the Shapefile set has all the required extensions;
   * otherwise, returning false.
   * @param {SmartSuiteFileListObject[]} smartSuiteFile - The SmartSuite file list object.
   * @returns {boolean} - True if at least one of the Shapefile set has all the required extensions; otherwise, false.
   */
  const hasRequiredShapefiles = (
    smartSuiteFile: SmartSuiteFileListObject[]
  ): boolean => {
    // Return false when there are no shapefiles
    if (smartSuiteFile.length === 0) return false;

    // Group the shapefiles by their name
    const shapefiles: Record<string, string[]> = {};
    for (const file of smartSuiteFile) {
      const [name, ext] = file.name.split('.');
      shapefiles[name] = shapefiles[name] ? [...shapefiles[name], ext] : [ext];
    }
    // Return the result of checking if at least one of the shapefiles has all the required extensions
    return Object.values(shapefiles).some((extensions) =>
      REQUIRED_SHAPEFILES_EXT.every((extension) =>
        extensions.includes(extension)
      )
    );
  };

  const hasRasterNumericalFiles: boolean = hasSmartSuiteFiles([
    ...rawRasterNumericalFiles,
    ...processedNumericalFiles,
  ]);

  const hasRasterCategoricalFiles: boolean = hasSmartSuiteFiles(
    rawRasterCategoricalFiles
  );

  const hasShapefiles: boolean = hasRequiredShapefiles(rawShapeFiles);

  const hasTargetVectorFiles: boolean =
    hasRequiredShapefiles(rawTargetVectorFiles);

  const hasTargetRasterFiles: boolean = hasSmartSuiteFiles(
    rawTrainingTargetFiles
  );

  /**
   * Reset all SmartSuite files
   */
  const resetSmartSuiteFiles = (): void => {
    setRawRasterNumericalFiles([]);
    setRawRasterCategoricalFiles([]);
    setRawShapeFiles([]);
    setRawTrainingTargetFiles([]);
    setRawTargetVectorFiles([]);
    setProcessedNumericalFiles([]);
  };

  /**
   * Group SmartSuite files based on their format and data type.
   * @param file - The SmartSuite file to be grouped.
   */
  const groupSmartSuiteFiles = (file: SmartSuiteFileListObject): void => {
    switch (file.format) {
      case SmartSuiteFileFormat.Raster:
        if (file.dataType === SmartSuiteDataType.Numerical) {
          setRawRasterNumericalFiles((prev) => [...prev, file]);
        } else if (file.dataType === SmartSuiteDataType.Categorical) {
          setRawRasterCategoricalFiles((prev) => [...prev, file]);
        }
        break;
      case SmartSuiteFileFormat.Shapefile:
        setRawShapeFiles((files: SmartSuiteFileListObject[]) => [
          ...files,
          file,
        ]);
        break;
      case SmartSuiteFileFormat.TrainingTargetRaster:
        setRawTrainingTargetFiles((prev) => [...prev, file]);
        break;
      case SmartSuiteFileFormat.TrainingTargetVector:
        setRawTargetVectorFiles((prev) => [...prev, file]);
        break;
    }
  };

  /**
   * Loads the directory items for the SmartSuite.
   * @description
   * Fetches the directory items for the SmartSuite app.
   * The directory items are then delegated to the corresponding SmartSuite file groups.
   * @param projectId - The ID of the project.
   * @returns A promise that resolves to an array of DirectoryItem objects.
   */
  const loadSmartSuiteDirectoryItems = async (
    projectId: string
  ): Promise<DirectoryItem[]> => {
    try {
      setAppLoading(true);
      const completeFilePath = `/${company}/${projectId}/files/`;
      const response: FileDirectoryResponse = await getFileDirectory(
        completeFilePath
      );
      const directoryItems = response.items;

      if (directoryItems) {
        await processFileDirectory(directoryItems);
        return directoryItems;
      }
    } catch (err: any) {
      errorHandler(err);
    } finally {
      setAppLoading(false);
    }

    return [];
  };

  const isSmartSuiteFile = (filename: string): boolean => {
    return Object.values(SmartSuiteFileExtensions).some((ext) =>
      filename.endsWith(ext)
    );
  };

  /**
   * Get the Smartsuite file's name, folder, and target folder.
   * @param {string} path - The Smartsuite file's path.
   * @returns {[string, string, string]} - The Smartsuite file's name, folder, and target folder.
   * For example,
   * If the path is goldspot/prospector-mike-lake/files/numerical/med3.tif, return [med3.tif, numerical, files/numerical]
   * If the path is goldspot/prospector-mike-lake/files/numerical/haralick_texture/htext.tif, return [htext.tif, haralick_texture, files/numerical/haralick_texture]
   * If the path is  goldspot/prospector-mike-lake/files/targets/ALS.tif, return [ALS.tif, targets, files/targets]
   * If the path is goldspot/prospector-mike-lake/files/targets/job.34.44.51.4/prc.tif, return [prc.tif, job.34.44.51.4, files/targets/job.34.44.51.4
   */
  const getSmartSuiteFilenameAndFolder = (
    path: string
  ): [string, string, string] => {
    const pathArray = path.split('/');
    const filename = pathArray[pathArray.length - 1];
    const folder = pathArray[pathArray.length - 2];
    const filesIndex = pathArray.indexOf('files');
    const targetFolder = pathArray
      .slice(filesIndex, pathArray.length - 1)
      .join('/');

    return [filename, folder, targetFolder];
  };

  /**
   * Adds a file to the SmartSuite file group if it meets certain criteria.
   * @description
   * The function first checks if the file has a 'sizeBytes' property. If it does, it splits the file path into an array of strings.
   * It then checks if the file is in a SmartSuite folder and if the file has a valid SmartSuite file extension.
   * If both conditions are met, it creates a new SmartSuiteFileListObject with properties from the file and the fileConfig.
   * Finally, it adds the new SmartSuiteFileListObject to the corresponding SmartSuite file group in the fileConfig, ensuring that only unique files are added.
   * However, if the file belongs to ancillary SmartSuite folder such as results of Targets Preparation jobs,
   * add the file to its respective SmartSuite file group.
   * @param {DirectoryItem} file - The file to be added to the SmartSuite file group.
   * @param {SmartSuiteFileConfig} fileConfig - The configuration object for SmartSuite files.
   */
  const addToSmartSuiteFileGroup = (
    file: DirectoryItem,
    fileConfig: SmartSuiteFileConfig
  ): void => {
    // If the file is invalid, do not add it
    if (!('sizeBytes' in file)) return;

    const [filename, folder, targetFolder] = getSmartSuiteFilenameAndFolder(
      file.path
    );

    const isFolderTargets: boolean = folder === 'targets';
    const isFileTargetRaster: boolean = /\.(tif|tiff)$/i.test(filename);

    // If the file is not a SmartSuite file, do not add it
    if (!isSmartSuiteFile(filename)) return;

    /**
     * If the file belongs to a known SmartSuite folder, add it to the corresponding file group.
     * Otherwise, add the file belonging to ancillary folder to its respective SmartSuite file group.
     */
    if (SMART_SUITE_APP_FOLDERS.includes(folder)) {
      const format: SmartSuiteFileFormat = isFolderTargets
        ? isFileTargetRaster
          ? SmartSuiteFileFormat.TrainingTargetRaster
          : SmartSuiteFileFormat.TrainingTargetVector
        : fileConfig[folder].format;
      const dataType: SmartSuiteDataType = isFolderTargets
        ? SmartSuiteDataType.Categorical
        : fileConfig[folder].dataType;

      const smartSuiteFile: SmartSuiteFileListObject = constructSmartSuiteFile(
        filename,
        file as CloudFile,
        format,
        dataType,
        targetFolder
      );

      // Determine the setter function based on the folder and file format
      const setter: SmartSuiteFileSetter = isFolderTargets
        ? isFileTargetRaster
          ? fileConfig.targetsRaster.setter
          : fileConfig.targetsVector.setter
        : fileConfig[folder].setter;

      setter((files: SmartSuiteFileListObject[]) => {
        if (!files.some((file) => file.name === filename)) {
          return [...files, smartSuiteFile];
        }
        return files;
      });
    } else {
      /**
       * For resulting Targets Preparation job files, add the raster files to the Training Target Files SmartSuite file group.
       * Some Targets Preparation jobs may produce similar files. In such cases, only the first file occurrence is added.
       */
      if (targetFolder.includes('files/targets/job')) {
        if (isFileTargetRaster) {
          const ancillaryFile: SmartSuiteFileListObject =
            constructSmartSuiteFile(
              filename,
              file as CloudFile,
              SmartSuiteFileFormat.TrainingTargetRaster,
              SmartSuiteDataType.Categorical,
              targetFolder
            );
          setRawTrainingTargetFiles((files: SmartSuiteFileListObject[]) =>
            files.some((file) => file.name === filename)
              ? files
              : [...files, ancillaryFile]
          );
        }
      }
    }
  };

  /**
   * Construct a SmartSuite file object.
   * @param {string} filename - The name of the file.
   * @param {CloudFile} file - The CloudFile object.
   * @param {SmartSuiteFileFormat} format - The format of the file.
   * @param {SmartSuiteDataType} dataType - The data type of the file.
   * @param {string} targetFolder - The target folder of the file.
   * @returns {SmartSuiteFileListObject} - The SmartSuiteFileListObject containing the file details.
   */
  const constructSmartSuiteFile = (
    filename: string,
    file: CloudFile,
    format: SmartSuiteFileFormat,
    dataType: SmartSuiteDataType,
    targetFolder: string
  ): SmartSuiteFileListObject => ({
    name: filename,
    size: file.sizeBytes,
    status: FileUploadStatus.done,
    completePath: `/${file.path}`,
    format,
    dataType,
    targetFolder,
  });

  /**
   * Return the file configuration object for the SmartSuite hook.
   * The file configuration object contains setters, data types, and formats for different file types.
   * @returns {SmartSuiteFileConfig} The file configuration object.
   */
  const getFileConfig = (): SmartSuiteFileConfig => ({
    numerical: {
      setter: setRawRasterNumericalFiles,
      dataType: SmartSuiteDataType.Numerical,
      format: SmartSuiteFileFormat.Raster,
    },
    categorical: {
      setter: setRawRasterCategoricalFiles,
      dataType: SmartSuiteDataType.Categorical,
      format: SmartSuiteFileFormat.Raster,
    },
    aoi: {
      setter: setRawShapeFiles,
      dataType: SmartSuiteDataType.Categorical,
      format: SmartSuiteFileFormat.Shapefile,
    },
    targetsRaster: {
      setter: setRawTrainingTargetFiles,
      dataType: SmartSuiteDataType.Categorical,
      format: SmartSuiteFileFormat.TrainingTargetRaster,
    },
    targetsVector: {
      setter: setRawTargetVectorFiles,
      dataType: SmartSuiteDataType.Categorical,
      format: SmartSuiteFileFormat.TrainingTargetVector,
    },
    moving_window_statistics: {
      setter: setProcessedNumericalFiles,
      dataType: SmartSuiteDataType.Numerical,
      format: SmartSuiteFileFormat.Raster,
    },
    haralick_texture: {
      setter: setProcessedNumericalFiles,
      dataType: SmartSuiteDataType.Numerical,
      format: SmartSuiteFileFormat.Raster,
    },
  });

  /**
   * Process the file directory by retrieving files from the given directory path and adding them to the smart suite file group.
   * @param items - An array of directory items.
   * @returns A Promise that resolves when the file directory processing is complete.
   */
  const processFileDirectory = async (
    items: DirectoryItem[]
  ): Promise<void> => {
    try {
      const fileConfig = getFileConfig();

      /**
       * Recursively retrieve files from the specified directory path.
       * @param path - The directory path.
       * @param offsetToken - The offset token for pagination (optional).
       */
      const getFilesFromDirectory = async (
        path: string,
        offsetToken?: string
      ): Promise<void> => {
        const queryParams = offsetToken ? { offset_token: offsetToken } : {};
        const response: FileDirectoryResponse = await getFileDirectory(
          path,
          queryParams
        );

        if (response.items) {
          const promises = response.items.map(async (file: DirectoryItem) => {
            addToSmartSuiteFileGroup(file, fileConfig);

            // Retrieve files from the subdirectory
            if (file.type === 'folder') {
              return getFilesFromDirectory(`/${file.path}`);
            }
          });

          // Wait for all Promises to resolve before continuing
          await Promise.all(promises);

          // If there's a next_offset_token, call retrieve for more files
          if (response.next_offset_token) {
            await getFilesFromDirectory(path, response.next_offset_token);
          }
        }
      };

      // For each folder in the directory, retrieve the files so that they can be added to the SmartSuite file group
      const folderPromises = items
        .filter((item) => item.type === 'folder')
        .map((item) => getFilesFromDirectory(`/${item.path}`));
      await Promise.all(folderPromises);
    } catch (err: any) {
      errorHandler(err);
    }
  };

  /**
   * Identifies the SmartSuite file based on the given TreeNode.
   * @description
   * The file format and data type are categorized based on the folder the file belongs to.
   * @param {TreeNode} node - The TreeNode object representing the file.
   * @returns {SmartSuiteFileListObject} - The SmartSuiteFileListObject containing the identified file details.
   */
  const identifySmartSuiteFile = (node: TreeNode): SmartSuiteFileListObject => {
    const pathArray = node.path.split('/');
    const filename = pathArray[pathArray.length - 1];
    const folder = pathArray[pathArray.length - 2];
    const targetFolder = `files/${folder}`;

    const folderAndFormatMap: { [key: string]: SmartSuiteFileFormat } = {
      numerical: SmartSuiteFileFormat.Raster,
      categorical: SmartSuiteFileFormat.Raster,
      aoi: SmartSuiteFileFormat.Shapefile,
      targets:
        filename.endsWith('.tif') || filename.endsWith('.tiff')
          ? SmartSuiteFileFormat.TrainingTargetRaster
          : SmartSuiteFileFormat.TrainingTargetVector,
    };

    const folderAndDataTypeMap: { [key: string]: SmartSuiteDataType } = {
      numerical: SmartSuiteDataType.Numerical,
      categorical: SmartSuiteDataType.Categorical,
      aoi: SmartSuiteDataType.Categorical,
      targets: SmartSuiteDataType.Categorical,
    };

    const format: SmartSuiteFileFormat = folderAndFormatMap[folder];
    const dataType: SmartSuiteDataType = folderAndDataTypeMap[folder];

    return {
      name: filename,
      size: node.fileData?.sizeBytes ?? 0,
      status: FileUploadStatus.done,
      completePath: node.path,
      format,
      dataType,
      targetFolder,
    };
  };

  /**
   * Deletes the specified SmartSuite file from the corresponding state arrays.
   * @param {TreeNode} node - The TreeNode representing the SmartSuite file to be deleted.
   */
  const deleteSmartSuiteFile = (node: TreeNode): void => {
    const theSmartSuiteFile = identifySmartSuiteFile(node);
    switch (theSmartSuiteFile.format) {
      case SmartSuiteFileFormat.Raster:
        if (theSmartSuiteFile.dataType === SmartSuiteDataType.Numerical) {
          setRawRasterNumericalFiles((prev) =>
            prev.filter((f) => f.name !== theSmartSuiteFile.name)
          );
        } else if (
          theSmartSuiteFile.dataType === SmartSuiteDataType.Categorical
        ) {
          setRawRasterCategoricalFiles((prev) =>
            prev.filter((f) => f.name !== theSmartSuiteFile.name)
          );
        }
        break;
      case SmartSuiteFileFormat.Shapefile:
        setRawShapeFiles((prev) =>
          prev.filter((f) => f.name !== theSmartSuiteFile.name)
        );
        break;
      case SmartSuiteFileFormat.TrainingTargetRaster:
        setRawTrainingTargetFiles((prev) =>
          prev.filter((f) => f.name !== theSmartSuiteFile.name)
        );
        break;
      case SmartSuiteFileFormat.TrainingTargetVector:
        setRawTargetVectorFiles((prev) =>
          prev.filter((f) => f.name !== theSmartSuiteFile.name)
        );
        break;
    }
  };

  /**
   * Find the exclusive domains for PCA jobs from the Cube job file reference data
   * @param {CubeBuildingJobData} cubeJobData - The Cube job data containing Cube information
   */
  const findDomainsForPCA = (cubeJobData: CubeBuildingJobData): void => {
    const fileRefData: CubeBuildingFileData[] =
      cubeJobData?.file_ref?.data ?? [];

    let domains: string[] =
      [
        ...new Set(
          fileRefData.map((data: CubeBuildingFileData) => data.domain)
        ),
      ] ?? [];

    // Remove 'categorical' from the domains in case it exists
    domains = domains.filter((domain) => domain !== 'categorical');

    setDomainsForPCA(domains);
  };

  // Get the input Cube file name from the Cube data
  const inputCubeFileName = useMemo(() => {
    return (
      cubeData?.cubes?.filter((fileName) => fileName.endsWith('.csv'))[0] ?? ''
    );
  }, [cubeData]);

  // Get the input PCA file name from the PCA data
  const outputCubeFileName = pcaData?.columns?.filename;

  return (
    <SmartSuiteContext.Provider
      value={{
        smartsuiteJobType,
        setSmartsuiteJobType,
        rawRasterNumericalFiles,
        rawRasterCategoricalFiles,
        rawShapeFiles,
        rawTrainingTargetFiles,
        rawTargetVectorFiles,
        processedNumericalFiles,
        resetSmartSuiteFiles,
        groupSmartSuiteFiles,
        loadSmartSuiteDirectoryItems,
        deleteSmartSuiteFile,
        isSmartSuiteFile,
        hasRasterNumericalFiles,
        hasRasterCategoricalFiles,
        hasShapefiles,
        hasTargetVectorFiles,
        hasTargetRasterFiles,
        numericalFileData,
        setNumericalFileData,
        cubeData,
        setCubeData,
        inputCubeFileName,
        outputCubeFileName,
        findDomainsForPCA,
        domainsForPCA,
        setDomainsForPCA,
        pcaData,
        setPcaData,
        pcaJobMetadata,
        setPcaJobMetadata,
        cubeBuildJobMetadata,
        setCubeBuildJobMetadata,
        modellingInputFeatures,
        setModellingInputFeatures,
        modellingTargetFiles,
        setModellingTargetFiles,
        modellingData,
        setModellingData,
        clusteringJobMetadata,
        setClusteringJobMetadata,
        clusteringData,
        setClusteringData,
        secondaryClusterSetting,
        setSecondaryClusterSetting,
      }}
    >
      {children}
    </SmartSuiteContext.Provider>
  );
};

interface SmartSuiteContextProviderProps {
  children: ReactNode;
}

type SmartSuiteFileSetter = Dispatch<
  SetStateAction<SmartSuiteFileListObject[]>
>;

interface SmartSuiteFileConfig {
  [key: string]: {
    setter: SmartSuiteFileSetter;
    dataType: SmartSuiteDataType;
    format: SmartSuiteFileFormat;
  };
}
