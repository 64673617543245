import { AuthenticatedApiRequest } from 'gsf-util-react-auth';
import {
  FileDirectoryParams,
  FileDirectoryResponse,
  SignedInUser,
} from '@/typescript/interfaces';
import { Config } from 'gsf-util-react-auth/lib/types';
import { parseQueryParams } from '@/utils/helpers';

export const getFile = (filePath: string): Promise<any> => {
  const fileExt: string = filePath.split('.')[filePath.split('.').length - 1];
  return new Promise((resolve, reject) => {
    AuthenticatedApiRequest({
      method: 'get',
      apiName: 'Files',
      path: filePath,
    })
      .then((response: any) => {
        const imageFileExtensions = ['jpg', 'jpeg', 'png'];
        // new GET /files response
        if (
          response.result &&
          typeof response.result === 'string' &&
          /^https:\/\//.test(response.result) &&
          !imageFileExtensions.includes(fileExt)
        ) {
          const tempFileUrl: string = response.result;
          return useTempUrl(tempFileUrl, fileExt);
        }
        // old GET /files response
        else {
          return response;
        }
      })
      .then((response: any) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const useTempUrl = (tempFileUrl: string, fileExt: string) => {
  return new Promise((resolve, reject) => {
    fetch(tempFileUrl)
      .then((response: Response) => {
        if (fileExt === 'json') {
          return response.json();
        } else {
          return response.blob();
        }
      })
      .then((result: any) => {
        if (fileExt !== 'json') {
          resolve(result.text());
        } else {
          resolve(result);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getFileDirectory = (
  path: string,
  queryParams?: FileDirectoryParams
): Promise<FileDirectoryResponse> => {
  return new Promise((resolve, reject) => {
    AuthenticatedApiRequest({
      method: 'get',
      apiName: 'Files',
      path: `${path}${parseQueryParams(queryParams)}`,
    })
      .then((result: any) => {
        const directoryResponse: FileDirectoryResponse = result;

        // any manipulation that is specific to this request
        resolve(directoryResponse);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const putFile = (
  filePath: string,
  file?: any,
  fileType?: string
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const requestConfig: Config = {
      method: 'put',
      apiName: 'Files',
      path: filePath,
    };
    if (file && fileType) {
      const postData = {
        body: file,
        headers: {
          'Content-Type': fileType,
        },
      };
      postData.body = file;
      postData.headers = {
        'Content-Type': fileType,
      };
      requestConfig.postData = postData;
    }
    AuthenticatedApiRequest(requestConfig)
      .then((result: any) => {
        if (result.result) {
          const headers: Headers = new Headers();
          if (fileType) {
            headers.append('Content-Type', fileType);
          }
          const requestInfo: any = {
            method: 'PUT',
            headers,
          };
          if (file) {
            requestInfo.body = JSON.stringify(file);
          }
          return fetch(result.result, requestInfo);
        } else {
          resolve(result);
        }
      })
      .then((result: any) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteFile = (path: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    AuthenticatedApiRequest({
      method: 'delete',
      apiName: 'Files',
      path,
    })
      .then((result: unknown) => {
        const data = result as { message?: string; error?: string };
        if (data.error) {
          reject(result);
        } else {
          resolve();
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const downloadFileFromS3 = (
  path: string,
  user: { username: string; signInUserSession?: any; attributes?: any }
): Promise<{ data: any; type: string }> => {
  const token: string = user.signInUserSession.getIdToken().getJwtToken();
  return new Promise((resolve, reject) => {
    const headers: Headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    const requestInfo: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
    };
    const file: { data: any; type: string } = {
      data: undefined,
      type: '',
    };
    const environment: string =
      process.env.REACT_APP_ENVIRONMENT === 'prod'
        ? ''
        : `.${process.env.REACT_APP_ENVIRONMENT}` || '';

    const formattedPath = path.startsWith('/') ? path.slice(1) : path;
    fetch(
      `https://files${environment}.api.goldspot.ca/${formattedPath}`,
      requestInfo
    )
      .then((response: Response) => {
        return response.json();
      })
      .then((response: any) => {
        if (response.result && typeof response.result === 'string') {
          return fetch(response.result);
        } else {
          throw new Error('No file found');
        }
      })
      .then((response: Response) => {
        file.type = response.headers.get('Content-Type') || '';
        return response.blob();
      })
      .then((data: Blob) => {
        file.data = data;
        resolve(file);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const uploadFileToS3 = (
  file: File,
  pathName: string,
  user: SignedInUser
): Promise<string> => {
  const token: string = user.signInUserSession.getIdToken().getJwtToken();
  return new Promise((resolve, reject) => {
    const headers: Headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    if (!file.type) {
      const fallbackMimes: Record<string, string> = {
        shx: 'application/vnd.shx',
        shp: 'application/vnd.shp',
        dbf: 'application/vnd.dbf',
        tif: 'application/octet-stream',
      };
      const extension: string = file.name.slice(file.name.lastIndexOf('.') + 1);
      headers.append('Content-Type', fallbackMimes[extension] || 'text/plain');
    } else {
      headers.append('Content-Type', file.type);
    }
    // can't do x-amz-meta yet as of Sept 2022: https://github.com/boto/boto3/discussions/3342#discussioncomment-3318636
    // headers.append('x-amz-meta-created-by', user.username);
    const requestInfo: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: null,
    };
    const environment: string =
      process.env.REACT_APP_ENVIRONMENT === 'prod'
        ? ''
        : `.${process.env.REACT_APP_ENVIRONMENT}` || '';
    fetch(
      `https://files${environment}.api.goldspot.ca/${pathName}`,
      requestInfo
    )
      .then((response: Response) => {
        const responseBody: Promise<any> = response.json();
        return responseBody;
      })
      .then((responseBody: any) => {
        if (responseBody.result) {
          const headers: Headers = new Headers();
          if (!file.type) {
            const fallbackMimes: Record<string, string> = {
              shx: 'application/vnd.shx',
              shp: 'application/vnd.shp',
              dbf: 'application/vnd.dbf',
              tif: 'application/octet-stream',
            };
            const extension: string = file.name.slice(
              file.name.lastIndexOf('.') + 1
            );
            headers.append(
              'Content-Type',
              fallbackMimes[extension] || 'text/plain'
            );
          } else {
            headers.append('Content-Type', file.type);
          }
          // can't do x-amz-meta yet as of Sept 2022: https://github.com/boto/boto3/discussions/3342#discussioncomment-3318636
          // headers.append('x-amz-meta-created-by', user.username);
          const requestInfo: any = {
            method: 'PUT',
            headers,
            body: file,
          };
          return fetch(responseBody.result, requestInfo);
        } else {
          reject(responseBody);
        }
      })
      .then((response?: Response) => {
        const etag: string | null = response?.headers.get('ETag') || null;
        if (etag) {
          const hash: string = JSON.parse(etag);
          resolve(hash);
        } else {
          reject();
        }
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

/**
 * Check if file with this path already exists (does not download file)
 * @returns true if file exists, false if file does not exist
 */
export const checkFileExists = (
  filePath: string,
  user: SignedInUser
): Promise<boolean> => {
  const token: string = user.signInUserSession.getIdToken().getJwtToken();
  return new Promise((resolve, reject) => {
    const headers: Headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    const requestInfo: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
    };
    const environment: string =
      process.env.REACT_APP_ENVIRONMENT === 'prod'
        ? ''
        : `.${process.env.REACT_APP_ENVIRONMENT}` || '';
    fetch(
      `https://files${environment}.api.goldspot.ca/${filePath}`,
      requestInfo
    )
      .then((response: Response) => {
        const responseBody: Promise<any> = response.json();
        return responseBody;
      })
      .then((responseBody: any) => {
        if (responseBody.result && /^https:\/\/.+/.test(responseBody.result)) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

/**
 * Get a json file and return the contents
 * @param {string} path - Path to the .json file
 * @returns {Promise<any>} - The contents of the .json file
 */
export const getJsonFile = async (path: string): Promise<any> => {
  try {
    const result: any = await AuthenticatedApiRequest({
      method: 'get',
      apiName: 'Files',
      path: `/${path}`,
    });

    const url: string = result.result;
    const response: Response = await fetch(url);

    // Replace NaN with "No Data" to prevent JSON parsing errors
    let text = await response.text();
    text = text.replace(/NaN/g, '"No Data"');

    const jsonFile: any = JSON.parse(text);
    return jsonFile;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
