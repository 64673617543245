/** @mui/material imports */
import {
  Box,
  Snackbar,
  Alert,
  Backdrop,
  CircularProgress,
  Button,
  IconButton,
} from '@mui/material';
/** @mui/icons-material imports */
import CloseIcon from '@mui/icons-material/Close';
/** React imports */
import React, { Context, createContext, useState } from 'react';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
/** Goldspot imports */
import { SignedInUser, AlertObject } from '@/typescript/interfaces';
import AppScopeLoadingComponent from '@/utils/components/AppScopeLoading';
import { AppContextProvider, useStore } from '@/hooks/useStore';
import { DialogAlertDetails } from '@/components/Dialogs/DialogAlertDetails';
import { SmartSuiteContextProvider } from '@/Contexts/SmartSuiteContext';
import { errorHandler } from '@/utils/error/error-handler';

const AppRouter = React.lazy(() => {
  return import('./Router/router');
});

interface AppContextType {
  addAppAlert: (value: AlertObject) => void;
}

export const AppAlertsContext: Context<AppContextType> =
  createContext<AppContextType>({
    addAppAlert: (value: AlertObject): void => {
      /** TODO */
    },
  });

interface AppProps {
  user: SignedInUser;
  signOut: () => void;
}

// Create a query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error, { meta }) => {
      errorHandler(error as Error);
      // Show error message
      if (meta?.errorMessage) {
        console.error(meta?.errorMessage);
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, _variables, _context, mutation) => {
      errorHandler(error as Error);
      // Show error message
      if (mutation.meta?.errorMessage) {
        console.error(mutation.meta?.errorMessage);
      }
    },
  }),
});

const App: React.FC<AppProps> = ({ user, signOut }): JSX.Element => {
  const { t } = useTranslation();
  const { appLoading } = useStore();
  const [appAlerts, setAppAlerts] = useState<AlertObject[] | []>([]);
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] =
    useState<boolean>(false);
  // High level state to control the Side Drawer via sibbling Navbar

  const addAppAlert = (alert: AlertObject): void => {
    setAppAlerts([...appAlerts, alert]);
  };

  const handleCloseAlert = (): void => {
    appAlerts.shift(); // Removes the first Alert (closing one Aler at a time)
    setAppAlerts([...appAlerts]);
  };

  const handleOpenAlertDetails = (): void => {
    setIsDetailsDialogOpen(true);
  };

  const handleCloseAlertDetails = (): void => {
    setIsDetailsDialogOpen(false);
  };

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AppContextProvider user={user}>
          <SmartSuiteContextProvider>
            <AppAlertsContext.Provider value={{ addAppAlert }}>
              <Box sx={{ display: 'flex' }}>
                <React.Suspense fallback={<AppScopeLoadingComponent />}>
                  <AppRouter user={user} signOut={signOut} />
                </React.Suspense>
              </Box>
              <Backdrop
                sx={{ color: '#fff', zIndex: '2000' }}
                open={appLoading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>

              {appAlerts.length > 0 && appAlerts[0].severity === 'error' ? (
                <>
                  <Snackbar open={true}>
                    <Alert
                      severity={appAlerts[0].severity}
                      action={
                        <>
                          {!!appAlerts[0].details && (
                            <Button
                              color="inherit"
                              size="small"
                              onClick={handleOpenAlertDetails}
                            >
                              {t('BUTTON_DETAILS')}
                            </Button>
                          )}
                          <IconButton
                            color="inherit"
                            size="small"
                            onClick={handleCloseAlert}
                          >
                            <CloseIcon />
                          </IconButton>
                        </>
                      }
                    >
                      {appAlerts[0].message}
                    </Alert>
                  </Snackbar>
                  <DialogAlertDetails
                    isDetailsDialogOpen={isDetailsDialogOpen}
                    onClose={handleCloseAlertDetails}
                    severity="error"
                    details={appAlerts[0].details || ''}
                  />
                </>
              ) : (
                appAlerts.length > 0 && (
                  <Snackbar
                    open={true}
                    autoHideDuration={3000}
                    onClose={handleCloseAlert}
                  >
                    <Alert
                      severity={appAlerts[0].severity}
                      onClose={handleCloseAlert}
                    >
                      {appAlerts[0].message}
                    </Alert>
                  </Snackbar>
                )
              )}
            </AppAlertsContext.Provider>
          </SmartSuiteContextProvider>
        </AppContextProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
