import { ThemeProvider } from '@mui/material/styles';
import { gsTheme } from '@/theme/themes/gs-theme';
import React from 'react';
// GENERAL STYLE
import './themes/gs-style.scss'; // last to override other styling

const StylerHOC = <T extends object>(
  WrappedComponent: React.ComponentType<T>
): React.FC<T> => {
  const ComponentWithTheme: React.FC<T> = (props: T): JSX.Element => {
    return (
      <ThemeProvider theme={gsTheme}>
        <WrappedComponent {...props} />
      </ThemeProvider>
    );
  };

  return ComponentWithTheme;
};

export default StylerHOC; // Naming it Styler because it add Theme and our special style/css
